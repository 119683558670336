<template>
    <div>
        <header-portrate /> 
        <exports-print ref="exportbar" />

        <div style="margin-bottom:70px !important;">
            <bread-crumbs :items="items"></bread-crumbs> 
            <tabs-comp :tabs="tabs" />
            <v-row class="mainrow m-2" :style="`direction:`+lang.dir+`;margin-top:20px !important;`">
                <v-col
                cols="12"
                md="10"
                sm="12">
                <form autocomplete="off">
                    <v-row>
                        <v-col cols="12" md="2" sm="12">
                            <label>{{lang.start_date}}</label>
                            <b-input-group>
                            <b-form-select class="inborder text-center" @change="doStart()" style="width:33%;background:#efefef;" v-model="sd.day" :options="daysList"/>
                            <b-form-select class="inborder text-center" @change="doStart()" style="width:33%;background:#efefef;border-right:none !important;" v-model="sd.month" :options="monthsList" />
                            <b-form-select class="inborder text-center" @change="doStart()" style="width:33%;background:#efefef;" v-model="sd.year" :options="yearsList" />
                            </b-input-group>
                        </v-col>
                        <v-col cols="12" md="2" sm="12">
                            <label>{{lang.end_date}}</label>
                            <b-input-group>
                            <b-form-select class="inborder text-center" @change="doStart()" style="width:33%;background:#efefef;" v-model="ed.day" :options="daysList" />
                            <b-form-select class="inborder text-center" @change="doStart()" style="width:33%;background:#efefef;border-right:none !important;" v-model="ed.month" :options="monthsList" />
                            <b-form-select class="inborder text-center" @change="doStart()" style="width:33%;background:#efefef;" v-model="ed.year" :options="yearsList" />
                            </b-input-group>
                        </v-col>

                        <v-col cols="12" md="2" sm="12">
                            <label></label>
                            <b-input-group>
                            <b-button variant="light" style="padding-top:2px !important;padding-bottom:2px !important;background:blue;color:#fff;width:75px;"  @click="getExpType()">{{lang.search}}</b-button>
                            </b-input-group>
                        </v-col>
                    </v-row>
                </form>
                <b-input-group class="mt-3" style="display:none"> 
                    <b-form-input placeholder="Start Date" style="border:1px solid #000;border-radius: 0;" type="date" v-model="sdate"></b-form-input>
                    <b-form-input placeholder="End Date" style="border:1px solid #000" type="date" v-model="edate"></b-form-input>
                    <b-input-group-append style="border:1px solid #000">
                        <b-button variant="light" style="background: gold !important;font-size:14px;color:#000;font-size:14px;" @click="getReport()"><i class="fas fa-search" style="color:000;font-weight:bold;"></i></b-button>
                    </b-input-group-append>
                </b-input-group>
                </v-col>
                <v-col cols="12" md="4" sm="12" class="text-right" style="display:none"> 
                    <b-button variant="light" style="width:145px;margin-right:9px" class="mytab mytab1 mt-3" @click="exportE('e')">{{lang.export_excel}}</b-button>
                    <b-button variant="light" style="width:145px;margin-right:3px;background:red;color:#FFF;display:none" class="mytab mt-3" @click="exportE('p')">PDF تصدير</b-button>
                </v-col> 
            </v-row>
            <v-row class="mainrow m-2" :style="`direction:`+lang.dir+`;margin-top:-10px !important;`">
                <v-col cols="12">
                    <div style="margin-bottom:50px;display:flex;flex-direction:row;flex-wrap:wrap;justify-content:center;justify-items:center;align-self:center;">
                        <div v-for="(item,index) in expType" :key="index" style="margin-bottom:5px;border:1px solid #000;width:16%;margin-inline:5px;padding:0">
                            <div class="backBlack" style="border:1px solid #bbb;padding:5px;text-align:center;">{{ item[`employee_name`] }}</div>
                            <div class="text-center" style="padding:5px;margin-bottom:2px;border-bottom:1px solid #bbb;display:flex;justify-content:space-between;"> <span style="margin-inline-start:10px;">أعمال لم تنته:</span>  <span style="margin-inline-end:10px;">{{ typeof item.opened === 'undefined' ? 0 : item.opened  }}</span></div>
                            <div class="text-center" style="padding:5px;margin-bottom:2px;border-bottom:1px solid #bbb;display:flex;justify-content:space-between;"> <span style="margin-inline-start:10px;">أعمال أنتهت:</span>  <span style="margin-inline-end:10px;">{{ typeof item.closed === 'undefined' ? 0 : item.closed  }}</span></div>
                            <div class="text-center" style="padding:5px;margin-bottom:2px;border-bottom:1px solid #bbb;display:flex;justify-content:space-between;"> <span style="margin-inline-start:10px;">اجمالي الدخل:</span>  <span style="margin-inline-end:10px;">{{ typeof item.closed_total === 'undefined' ? 0 : item.closed_total  }}</span></div>
                            <div class="blackBack" style="border:1px solid #bbb;padding:5px;text-align:center;cursor:pointer" @click="showTable(item)">{{ lang.view }}</div>
                        </div>
                    </div>
                </v-col>
            </v-row>
        </div>
        <Footer />
        <div class="cusModal" v-if="showtable">
            <div class="cusModalCont">
                <div class="cusModalTitle backBlack">{{ table_titel }}
                    <div class="cusModalClose" @click="closeTable()">{{ lang.close }}</div>
                </div>
                <div class="cusModalBody">
                    <v-data-table
                        :headers="headers1"
                        :items="purchrows"
                        :search="search"
                        :items-per-page="10"
                        class="elevation-1"
                        :page.sync="page"
                        hide-default-footer
                        @page-count="pageCount = $event"
                    >
                    <template v-slot:item="row">
                        <tr>
                            <td class="text-center" style="width:70px">{{ row.item.card_date }}</td>
                            <td class="text-center" style="width:70px">{{ row.item.cardid }}</td>
                            <td class="text-center" nowrap>{{ row.item.item_name }}</td>
                            <td class="text-center" nowrap>{{row.item[`_status_${lang.langname}`]}}</td>
                            <td class="text-center">{{ row.item.total }}</td>
                            <td class="text-center">{{ row.item.vat }}</td>
                            <td class="text-center" style="width:100px">{{ row.item.ftotal }}</td>
                    </tr>
                    </template>
                    <template slot="body.append">
                            <tr>
                                <th class="backEmpty"> </th>
                                <th class="backEmpty"> </th>
                                <th class="backEmpty"> </th>
                                <th class="backBlack" style="text-align:center;font-size:1.2em;font-weight:bold">{{lang.totals}}</th>
                                <th class="backBlack" style="text-align:center;font-size:1.2em;font-weight:bold">{{ptotals}}</th>
                                <th class="backBlack" style="text-align:center;font-size:1.2em;font-weight:bold">{{pvat}}</th>
                                <th class="backBlack" style="text-align:center;font-size:1.2em;font-weight:bold">{{pftotals}}</th>
                            </tr>
                    </template>
                    </v-data-table>
                    <v-container style="direction:rtl">
                        <div class="text-center pt-2" style="direction:rtl">
                        <v-pagination
                            v-model="page"
                            value="10"
                            :length="pageCount"
                            style="direction:rtl"
                            color="#000"
                        ></v-pagination>
                        </div>
                    </v-container>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import BreadCrumbs from '@/components/breadCrumbs.vue';
import TabsComp from '@/components/tabsComp.vue';
import HeaderPortrate from '@/components/Header-Portrate.vue';
import Footer from '@/components/Footer.vue';
// import {mapActions, mapGetters} from 'vuex'
import axios from 'axios'
import ExportsPrint from '@/components/exports-print.vue'

export default{
    components: {BreadCrumbs, TabsComp, HeaderPortrate, Footer,ExportsPrint},
    data() {
        return {
            search: '',
            purchrows: [],
            items: {},
            inoutRows: [],
            sd: {
                day: 1,
                month: 1,
                year: (new Date()).getFullYear(),
            },
            ed: {
                day: (new Date()).getDate() + 1,
                month: (new Date()).getMonth() + 1,
                year: (new Date()).getFullYear(),
            },
            headers:[],
            showtable:false,
            table_titel: 'ssss',
            page: 1,
            pageCount:0,
            expType: []
        }
    },
    computed: {
        lang: function(){
            if(this.$cookies.get(this.$store.state.LangCooki) != null){
                if(this.$cookies.get(this.$store.state.LangCooki)  == 'en'){
                    return this.$store.state.lang.en;
                }else{
                    return this.$store.state.lang.ar;
                }
            }
            else if(this.$store.state.deflang == 'en'){
                return this.$store.state.lang.en;
            }else{
                return this.$store.state.lang.ar;
            }
        },
        sdate: function() {
            return (new Date(this.sd.year,this.sd.month-1, this.sd.day+1)).toISOString().substring(0,10);
        },
        edate: function() {
            return (new Date(this.ed.year,this.ed.month-1, this.ed.day+1)).toISOString().substring(0,10);
        },
        yearsList: function(){
            const start_year = this.$store.state.licenseType.startYear;
            let t = [{text: this.lang.year, value: -1}];
            for(let i=0;i<100;i++){
            if(parseInt(start_year) + i <= (new Date()).getFullYear()){
                t.push({text: start_year + i, value: start_year+i})
            }
            
            }
            return t
        },
        daysList: function(){
            return [
            {text: this.lang.day, value: -1},
            {text: 1, value: 1},
            {text: 2, value: 2},
            {text: 3, value: 3},
            {text: 4, value: 4},
            {text: 5, value: 5},
            {text: 6, value: 6},
            {text: 7, value: 7},
            {text: 8, value: 8},
            {text: 9, value: 9},
            {text: 10, value: 10},
            {text: 11, value: 11},
            {text: 12, value: 12},
            {text: 13, value: 13},
            {text: 14, value: 14},
            {text: 15, value: 15},
            {text: 16, value: 16},
            {text: 17, value: 17},
            {text: 18, value: 18},
            {text: 19, value: 19},
            {text: 20, value: 20},
            {text: 21, value: 21},
            {text: 22, value: 22},
            {text: 23, value: 23},
            {text: 24, value: 24},
            {text: 25, value: 25},
            {text: 26, value: 26},
            {text: 27, value: 27},
            {text: 28, value: 28},
            {text: 29, value: 29},
            {text: 30, value: 30},
            {text: 31, value: 31},
            ]
        },
        monthsList: function(){
            return [
            {text: this.lang.chose_month, value: -1},
            {text: 1, value: 1},
            {text: 2, value: 2},
            {text: 3, value: 3},
            {text: 4, value: 4},
            {text: 5, value: 5},
            {text: 6, value: 6},
            {text: 7, value: 7},
            {text: 8, value: 8},
            {text: 9, value: 9},
            {text: 10, value: 10},
            {text: 11, value: 11},
            {text: 12, value: 12},
            ]
        },
        tabs: function() {
            const menu = this.$store.getters['state'].menu;
            const license = this.$store.getters['state'].licenseType.activemenu;
            const alicense = this.$store.getters['state'].licenseType
            let currentMenu = menu[license];
            if(!license){
              return false;
            }
            let cMenu = {main: {},sub:[]};
            Object.values(currentMenu).forEach(value =>{
            const cM = localStorage.getItem('currentMenu');
            if(value.main.index == cM){
                cMenu = value.sub;
            }else if(cM == null && value.main.index == 1){
                cMenu = value.sub;
            }
            });
        
            let t = []
            Object.values(cMenu).forEach(value =>{
            
                if(this.$route.path == value.href){
                    value.class = "mytab";
                }else{
                    value.class = "mytab4";
                }
                if(this.lang.langname == 'ar'){
                    value.name = value.arname
                }else{
                    value.name = value.enname
                }
                for(let i =0; i< value.groupid.length;i++){
                    if(value.groupid[i] == this.$store.getters['state'].group_id){
                    if(alicense[value.perms[0]] || value.perms[0] == 'any')
                        t.push(value)
                    }
                }
            
            })
        
            return t;
        },
        headers1: function() {
            return [
                {
                    text: "تاريخ الكرت",
                    align: 'center',
                    sortable: true,
                    value: 'bill_number',
                    class:'backBlack',printRemove: 0
                },
                {
                    text: "رقم أمر التشغيل",
                    align: 'center',
                    sortable: true,
                    value: 'bill_number',
                    class:'backBlack',printRemove: 0
                },
                {
                    text: "المهمة",
                    align: 'center',
                    sortable: false,
                    value: 'vendor',
                    class:'backBlack',printRemove: 0
                },
                {
                    text: "الحالة",
                    align: 'center',
                    sortable: false,
                    value: 'type',
                    class:'backBlack',printRemove: 0
                },
                {
                    text: "اجمالي الدخل",
                    align: 'center',
                    sortable: false,
                    value: 'bill_date',
                    class:'backBlack',printRemove: 0
                },
                {
                    text: "الضريبة",
                    align: 'center',
                    sortable: false,
                    value: 'total',
                    class:'backBlack',printRemove: 0
                },
                {
                    text: "الاجمالي مع الضريبة",
                    align: 'center',
                    sortable: false,
                    value: 'vat',
                    class:'backBlack',printRemove: 0
                },
                
            ]
        },
        ptotals: function(){
            let x = 0;
            for(let i = 0;i < this.purchrows.length;i++){
                x = parseFloat(this.purchrows[i].total) + parseFloat(x);
            }
            return this.$RoundNum(x);
        },
        pvat: function(){
            let x = 0;
            for(let i = 0;i < this.purchrows.length;i++){
                x = parseFloat(this.purchrows[i].vat) + parseFloat(x);
            }
            return this.$RoundNum(x);
        },
        pftotals: function(){
            let x = 0;
            for(let i = 0;i < this.purchrows.length;i++){
                x = parseFloat(this.purchrows[i].ftotal) + parseFloat(x);
            }
            return this.$RoundNum(x);
        },
    },
    methods:{
        getStartDate(){
            let d = (new Date()).getDate();
            let m = (new Date()).getMonth() + 1;
            let y = (new Date()).getFullYear();
            if(parseInt(d) < 30){
            d = parseInt(30) - parseInt(d);
            if(parseInt(m) == 1){
                m = 12
                y = parseInt(y) - 1
            }
            else if(parseInt(m) > 1){
                m = parseInt(m) - 1;
            }
            }else if(parseInt(d) == 30){
            d = 1;
            }else{
            d = parseInt(d) - parseInt(30);
            }
            this.sd.day = 1;
            this.sd.month = 1;
            this.sd.year = y;
        },
        showTable(item){
            this.showtable = true;
            this.table_titel = item[`employee_name`];
            this.id = item.id
            this.expeneses_type = item.classid;
            this.getInvoice();
        },
        closeTable(){
            this.showtable = false;
            this.table_titel = "";
            this.id = 0
            this.expeneses_type = 0;
            this.purchrows = []
        },
        preparedata(){ 
            let t = this.headers;
            let data = this.headers;
            let hdata = [];
            let exdata = [];
            for(let i=0;i<t.length;i++){
                if(t[i].printRemove == 0)
                    hdata.push(t[i].text);
            }
            hdata.push('')
            exdata.push(hdata);
            let x = [];
            for(let kk=0;kk<data.length;kk++){ 
                x.push(data[kk].total)
            }
        
            exdata.push(x);
            
            this.$refs.exportbar.exportdata = exdata;
            this.$refs.exportbar.sdate = this.sdate;
            this.$refs.exportbar.edate = this.edate;
  
        },
        // getExpense(){
        //     const post = new FormData();
        //     post.append("type","getExpenes");
        //     post.append('auth',this.$cookies.get(this.$COOKIEPhase));
        //     post.append('data[type]',this.getExpense);
        //     post.append('data[expenses]',this.$store.getters['state'].licenseType.onlySalesPurchase ? 0 : 1);

        // },
        getExpType(){
            const post = new FormData();
            post.append("type","getKPIList");
            post.append('defBranch',localStorage.getItem('defBranch'));
            post.append('financeYear',localStorage.getItem('financeYear'));
            post.append('auth',this.$cookies.get(this.$COOKIEPhase));
            post.append('data[sdate]',this.sdate);
            post.append('data[edate]',this.edate);
            // post.append('data[type]',this.getExpense);
            post.append('data[expenses]',this.$store.getters['state'].licenseType.onlySalesPurchase ? 0 : 1);
            let myar = [];
            axios.post(
                this.$store.state.SAMCOTEC.r_path, post
            ).then((response) => {
                const res = response.data;
                // console.log(res);
                this.expType = res.results.data;
                if(res.results.data.length != 0){
                    for(let i=0;i<res.results.data.length;i++){
                        myar.push(
                            {
                                text: (this.lang.langname == "ar") ? res.results.data[i].namear : res.results.data[i].nameen, 
                                value: res.results.data[i].classid,
                                total: res.results.data[i].expens_total,
                                expeneses_type: res.results.data[i].expeneses_type,
                                printRemove: 0
                            }
                        );
                    }
                }
                
                this.headers = myar;
            })
        },
        getInvoice(){
            const post = new FormData();
            post.append('type','getKPIList');
            post.append('defBranch',localStorage.getItem('defBranch'));
            post.append('financeYear',localStorage.getItem('financeYear'));
            post.append('auth',this.$cookies.get(this.$COOKIEPhase));
            post.append('data[id]',this.id);
            post.append('data[sdate]',this.sdate);
            post.append('data[edate]',this.edate);
            this.purchrows = [];
            axios.post(
                this.$SAMCOTEC.r_path, post
            ).then((response) => {
                
                const res = response.data;
                // // console.log(res);
                this.purchrows = res.results.allres;
            });
        },
    },
    created(){
        this.getStartDate();
        this.getExpType();
    }
}
</script>

<style scoped>
.cusModal{
    position: fixed;
    background:#0000001a;
    top:0;
    left:0;
    bottom:0;
    right:0;
    z-index: 20000000;
    display:flex;
    justify-content: center;
    justify-items: center;
    align-items: center;;
}
.cusModalCont{
    position:relative;
    width:70%;
    height: 70vh;
    border:1px solid #bbb;
    background:#fff;
    box-shadow: 0px 1px 1px 1px #0000001a;
    border-radius: 5px;
}
.cusModalClose{
    position: absolute;
    top:2px;
    left:10px;
    background:red;
    color:#fff;
    border:1px solid #555;
    border:#ccc;
    border-radius: 2px;
    padding:4px 10px;
    cursor: pointer;
    box-shadow: 0px 1px 1px 1px #0000001a;
}
.cusModalCloseL:hover{
    background: darkred;
}
.cusModalTitle{
    padding:5px;
    text-align:center;
    border-color: #bbb !important;
    border-bottom-color: #000 !important;
}
.cusModalBody{
    overflow: auto;
    height:calc(70vh - 50px);
    max-height:calc(70vh - 50px);
    padding:5px;
}
</style>
